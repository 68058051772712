import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import { Trans, useTranslation } from "react-i18next";
import { IntercomLauncher } from "../../Components/IntercomLauncher/IntercomLauncher";

const CompanyDoesNotExist = () => {
  const { t } = useTranslation("companyDoesNotExist");

  return (
    <div
      className={
        "flex h-screen w-screen items-center justify-center bg-white bg-contain bg-no-repeat"
      }
      style={{ backgroundImage: 'url("/login-top.png")' }}
    >
      <div className={"flex items-center justify-center"}>
        <div className="flex flex-col gap-4 rounded-2xl bg-slate-50 px-8 pt-12 shadow">
          <div className="flex items-center gap-4">
            <div className="flex h-48 w-48 items-center justify-center rounded-full bg-white">
              <img
                alt="kamae-san"
                src="/kamae-san.png"
                className="h-44"
              />
            </div>
            <div className={"w-2/3"}>
              <p className="text-3xl">{t("companyDoesNotExist:title")}</p>
              <p className="text-2xl text-orange-400">
                {t("companyDoesNotExist:subTitle")}
              </p>
            </div>
          </div>
          <div className={"mt-3 flex flex-col items-center gap-3"}>
            <p className={"font-gilroy-medium text-xl text-rose-500"}>
              <Trans
                t={t}
                ns={"companyDoesNotExist"}
                i18nKey={"description"}
              />
            </p>
            <IntercomLauncher>
              <PrimaryButton>{t("companyDoesNotExist:action")}</PrimaryButton>
            </IntercomLauncher>
          </div>
          <div className="flex justify-center pb-4">
            <img
              alt="logo"
              src="logo_plateforme.png"
              className="h-12"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDoesNotExist;
